<template>
	<!-- 设计与施工技术首页 -->
	<div id="build">
		<!-- 商品分类 -->
		<Classification
			:types="1"
			:navData="$store.state.navData"
			:sidebarData="$store.state.sidebarData"
		></Classification>
		<img
			class="build_banner"
			src="../../assets/img/build/build_banner.png"
			alt=""
		/>
		<div v-if="true">
			<div class="build_header">
				<div
					class="build_heder_main"
					v-for="item in titleArticle"
					:key="item.id"
				>
					<p class="build_heder_title" @click="toDetail(item.id)">
						{{ item.title }}
					</p>
					<div class="build_header_detail">
						<img
							class="build_header_detail_img"
							:src="item.coverUrl"
							@click="toDetail(item.id)"
							alt=""
						/>
						<div class="build_header_detail_text" v-html="item.content"></div>
					</div>
				</div>
			</div>
			<div class="build_body">
				<div class="build_body_main">
					<div
						:class="
							Math.round(index / 2) == index / 2
								? 'build_body_detail left'
								: 'build_body_detail right'
						"
						v-for="(item, index) in articleList"
						:key="item.id"
					>
						<img
							:class="
								Math.round(index / 2) == index / 2
									? 'build_body_detail_img detail_left'
									: 'build_body_detail_img detail_right'
							"
							@click="toDetail(item.id)"
							:src="item.coverUrl"
							alt=""
						/>
						<div
							:class="
								Math.round(index / 2) == index / 2
									? 'build_body_detail_text detail_left'
									: 'build_body_detail_text detail_right'
							"
						>
							<p class="build_body_detail_title" @click="toDetail(item.id)">
								{{ item.title }}
							</p>
							<div
								class="build_body_detail_titleText"
								v-html="item.content"
								@click="toDetail(item.id)"
							></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Classification from '@/components/classification.vue'
export default {
	name: 'GejMallPcIndex',
	components: {
		Classification,
	},

	data() {
		return {
			// 标题文章
			titleArticle: [],
			// 文章列表
			articleList: [],
		}
	},

	mounted() {
		this.buildInit()
	},

	methods: {
		// 跳转详情页
		toDetail(id) {
			let routeData = this.$router.resolve({
				path: '/detail/buildDetail',
				query: {
					id: id,
				},
			})
			window.open(routeData.href, '_blank')
		},
		// 查询文章列表
		buildInit() {
			this.api.findArticleLists().then((res) => {
				let data = res.data
				this.titleArticle = data.splice(0, 1)
				this.articleList = data
			})
		},
	},
}
</script>

<style lang="scss" scoped>
#build {
	.build_banner {
		width: 100%;
		height: 300px;
	}
	.build_header {
		height: 520px;
		width: 100%;
		background: white;
		.build_heder_main {
			width: 1200px;
			height: 520px;
			margin: 0 auto;
			overflow: hidden;
			display: flex;
			flex-wrap: wrap;
			align-content: space-around;
			justify-content: space-around;
			.build_heder_title {
				// margin-top: 59px;
				// margin-bottom: 44px;
				text-align: center;
				font-size: 30px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #333333;
				cursor: pointer;
			}
			.build_header_detail {
				width: 100%;
				display: flex;
				justify-content: space-between;
				.build_header_detail_img {
					width: 558px;
					height: 298px;
					cursor: pointer;
				}
				.build_header_detail_text {
					margin-top: 34px;
					width: 617px;
					// height: 184px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 28px;
				}
			}
		}
	}
	.build_body {
		overflow: hidden;
		margin: 0 auto;
		width: 1200px;
		// min-height: 1460px;
		.build_body_main {
			width: 100%;
			// min-height: 1280px;
			margin-bottom: 100px;
			margin-top: 81px;
			float: left;
			.left {
				.detail_left {
					float: left;
				}
				.build_body_detail_text {
					margin-left: 21px;
				}
			}
			.right {
				.detail_right {
					float: right;
				}
				.build_body_detail_text {
					margin-right: 38px;
				}
			}
			.build_body_detail {
				float: left;
				.build_body_detail_img {
					width: 600px;
					height: 320px;
					cursor: pointer;
				}
				.build_body_detail_text {
					width: 561px;
					.build_body_detail_title {
						cursor: pointer;
						font-size: 24px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						margin-top: 48px;
						margin-bottom: 21px;
					}
					.build_body_detail_titleText {
						cursor: pointer;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 25px;
						overflow: hidden;
						display: -webkit-box;
						text-overflow: ellipsis;
						-webkit-line-clamp: 8;
						-webkit-box-orient: vertical;
						// 将富文本中的img隐藏掉不显示
						::v-deep p {
							img {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
</style>